import React from "react"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import Img from "gatsby-image"
import Masonry from "react-masonry-component"
import "flag-icon-css/css/flag-icon.css"
import SEO from "../components/seo"
import * as PropTypes from "prop-types"

if (typeof window !== `undefined`) {
  window.postsToShow = 8
}
class IndexPage extends React.Component {
  static propTypes = {
    location: PropTypes.object.isRequired,
    data: PropTypes.shape({
      user: PropTypes.object,
      allPostsJson: PropTypes.object,
    }),
  }

  constructor() {
    super()
    let postsToShow = 8
    if (typeof window !== `undefined`) {
      postsToShow = window.postsToShow
    }

    this.state = {
      showingMore: postsToShow > 8,
      postsToShow,
    }
  }

  update() {
    const distanceToBottom =
      document.documentElement.offsetHeight -
      (window.scrollY + window.innerHeight)
    if (this.state.showingMore && distanceToBottom < 100) {
      this.setState({ postsToShow: this.state.postsToShow + 3 })
    }
    this.ticking = false
  }

  handleScroll = () => {
    if (!this.ticking) {
      this.ticking = true
      requestAnimationFrame(() => this.update())
    }
  }

  componentDidMount() {
    window.addEventListener(`scroll`, this.handleScroll)
  }

  componentWillUnmount() {
    window.removeEventListener(`scroll`, this.handleScroll)
    window.postsToShow = this.state.postsToShow
  }

  render() {
    let data = this.props.data

    return (
      <Layout>
        <SEO title="Home" />
        <h1 className="text-white text-center mb-10">
          Johnny Bob's Vintage Bikes
        </h1>
        <h3 className="secondary text-white text-center mb-16 px-2">
          Vintage racing bikes from the great makes and makers
        </h3>
        <Masonry>
          {data.allStrapiAlbum.nodes
            .slice(0, this.state.postsToShow)
            .map(node => (
              <div className="transition duration-500 ease-in-out transform hover:-translate-y-4 w-full md:w-1/2 lg:w-1/3 xl:w-1/3 p-4">
                <div className="rounded overflow-hidden shadow-2xl bg-gray-200">
                  <Link to={`/album/${node.strapiId}`}>
                    <Img fluid={node.CoverPhoto.childImageSharp.fluid} />
                  </Link>
                  <div className="px-6 py-4 flex">
                    <div className="font-bold text-xl truncate w-11/12">
                      {node.Title}
                    </div>
                    <div className="w-2/12 float-right -mr-8 -mt-2">
                      <span
                        className={`flag flag-icon flag-icon-${node.Country}`}
                      ></span>
                    </div>
                  </div>
                </div>
              </div>
            ))}
        </Masonry>
        {!this.state.showingMore && (
          <div className="flex flex-col items-center pt-10">
            <a
              onClick={() => {
                this.setState({
                  postsToShow: this.state.postsToShow + 8,
                  showingMore: true,
                })
              }}
            >
              <button className="hover px-6 py-2 transition ease-in duration-200 uppercase rounded-full bg-blue-500 hover:bg-gray-800 hover:text-white border-2 border-gray-900 focus:outline-none">
                Load more bikes
              </button>
            </a>
          </div>
        )}
      </Layout>
    )
  }
}

export default IndexPage

export const pageQuery = graphql`
  query IndexQuery {
    allStrapiAlbum {
      nodes {
        strapiId
        Title
        CoverPhoto {
          childImageSharp {
            fluid(maxWidth: 800, quality: 75) {
              ...GatsbyImageSharpFluid
            }
          }
        }
        Country
      }
    }
  }
`
